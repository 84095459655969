
import {Component, Vue, Prop} from "vue-property-decorator"
import { Order } from '@/services/SOLO'
import moment from 'moment'
import { Table, TableColumn, Button } from "element-ui"
import { translations } from '@/mixins'
import Translations from './Translations.vue'
import DeliveryLogDriverModal from './DeliveryLogDriverModal.vue';
import DeliveryLogPayloadResponseModal from './DeliveryLogPayloadResponseModal.vue';

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    DeliveryLogDriverModal,
    DeliveryLogPayloadResponseModal,
  },
  filters: {
    formatDate(date: string, format: string = 'MMM D YYYY hh:mm A') {
      return moment
        .utc(date, "YYYY-MM-D hh:mm:ss")
        .locale("en-us")
        .local()
        .format(format) || '';
    }      
  },
  mixins: [translations],
})
export default class Events extends Vue {

  logs: any = [];
  loading: Boolean = false;
  @Prop() orderId!: Boolean;
  openPayload: boolean = false;
  openDriver: boolean = false;
  response: any = {};
  payload: any = {};
  driver: any = [];

  payloadOpen(open: any, response: any, payload: any) {
    this.openPayload = open
    this.response = response
    this.payload = payload
  }

  payloadClose(value: any) {
    this.openPayload = value
  }

  driverOpen(open: any, driver: any) {
    this.driver = [];
    this.openDriver = open
    if (driver) {
      // driver = JSON.parse(driver)
      Object.keys(driver).forEach((e: any) => {
        this.driver.push({
          label: e,
          value: driver[e]
        })
      })
    }
  }

  driverClose(value: any) {
    this.openDriver = value
  }

  mounted() {
    this.getLogs();
  }

  async getLogs() {
    this.loading = true;
    await Order.getDeliveryLogs(this.orderId)
      .then((response: any) => {

         let e = response.data.data;

         this.logs = e.sort((a: any, b: any) => {
            return new Date(b.attributes['created-at']).getTime() - new Date(a.attributes['created-at']).getTime();
         });
         this.loading = false;
        console.log("check delivery logs", response.data.data);
      })
  }
  filterTag(value: any, row: any) {
      return row.attributes['level'] === value;
   }
  capitalizeFirstLetter(str: any) {
    if (typeof str !== 'string') return ''
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
}
