
import {
  Table,
  TableColumn
} from "element-ui";
import Modal from "@/components/Modal.vue";
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import {translations} from '@/mixins'
import moment from "moment"

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  filters: {
    formatDate(date: string, format: string = 'MMM D YYYY hh:mm A') {
      return moment
        .utc(date, "YYYY-MM-D hh:mm:ss")
        .locale("en-us")
        .local()
        .format(format) || '';
    }      
  },
  mixins: [translations],
})

export default class DeliveryLogDriverModal extends Vue {
  @Prop() openDriver: boolean = false
  @Prop() driver?: Object

  constructor() {
    super();
  }

  get isOpen(): Boolean {
    return this.openDriver;
  }

  set isOpen(newVal: Boolean) {
    this.$emit("openDriver", newVal);
    if (!newVal) {
    }
  }


  @Watch('openDriver', {deep: true})
  onOpenDriverChanged(newVal: any) {
    this.isOpen = newVal
  }

  handleClose() {
    this.$emit('driverClose', false)
  }

  mounted() {
    console.log('mounted driver')
  }
}
